import ControlRendererContext, {
    ControlRendererContextShape,
} from '@apphiveio/controlsmanager/ControlRendererContext'
import { Processor } from '@apphiveio/controlsmanager/types/Runtime'
import { AppAtomRenders } from '@apphiveio/controlsmanager/types/RenderComponents'
import buildApphiveProcessor from '@apphiveio/controlsmanager/applicationFunctionality/buildApphiveProcessor'
import { buildAppBootLocalStorageAction } from '@apphiveio/controlsmanager/applicationState/applicationStateActionBuilders'
import React, { useEffect, useMemo, useState } from 'react'
import {
    View,
    Text,
    TouchableOpacity,
    FlatList,
    Modal,
    SectionList,
    Switch,
    Animated,
    ScrollView,
} from 'react-native-web'
import store from 'store'
import { AllFunctions } from '@apphiveio/controlsmanager/types/Functions'
import { BuildOrders } from '@apphiveio/controlsmanager/types/Controls'
import SvgIcon from '@apphiveio/controlsmanager/components/SvgIcon'
import GenerateControlEventsFactory from '@apphiveio/controlsmanager/applicationFunctionality/GenerateControlEventsFactory'
import firebaseGetFunctions from 'shared/firebaseGetFunctions'
import { odCreatorApp } from 'firebaseClient'
import useCurrentLanguageEffect from 'hooks/useCurrentLanguageEffect'
import ControlSvgImage from 'components/atoms/ControlSvgImage'
import ControlLottie from 'components/atoms/ControlLottie'
import ControlImageBackground from 'components/atoms/ControlImageBackground'
import ControImage from 'components/atoms/ControImage'
import ControlSlider from 'components/atoms/ControlSlider'
import ControlCheckBox from 'components/atoms/ControlCheckBox'
import ControlPicker from 'components/atoms/ControlPicker'
import ControlInputMask from 'components/atoms/ControlInputMask'
import ControlCalendar from 'components/atoms/ControlCalendar'
import ControlDatePicker from 'components/atoms/ControlDatePicker'
import ControlCameraView from 'components/atoms/ControlCameraView'
import ControlVideoView from 'components/atoms/ControlVideoView'
import ControlWebView from 'components/atoms/ControlWebView'
import ControlMapWrapper from 'components/atoms/ControlMapWrapper'
import ControlMapView from 'components/atoms/ControlMapView'
import ControlMapMarker from 'components/atoms/ControlMapMarker'
import ControlAnimatedFlatList from 'components/atoms/ControlAnimatedFlatList'
import ControlPlacesAutoComplete from 'components/atoms/ControlPlacesAutoComplete'
import ControlSkeletonContent from 'components/atoms/ControlSkeletonContent'
import ControlGraphic from 'components/atoms/ControlGraphic'
import ControlCamera from 'components/atoms/ControlCamera'
import ControlVideo from 'components/atoms/ControlVideo'
import ControlTextInput from 'components/atoms/ControlTextInput'
import getRenderConfig from 'shared/getRenderConfig'
import webappFunctions from './webappFunctions'
import imageStaticSourcesByFileName from '../../../imageStaticSourcesByFileName'

export const WEBAPP_DIMENTIONS = {
    windowWidth: 0,
    windowHeight: 0,
}

const Render: AppAtomRenders = {
    View,
    Text,
    TouchableOpacity,
    Image: ControImage,
    StatusBar: (() => <div />),
    BannerAd: () => null,
    Calendar: ControlCalendar,
    CameraView: ControlCameraView,
    CheckBox: ControlCheckBox,
    DatePicker: ControlDatePicker,
    FlatList,
    GooglePlacesAutocomplete: ControlPlacesAutoComplete,
    ImageBackground: ControlImageBackground,
    KeyboardAvoidingView: View,
    Lottie: ControlLottie,
    MapMarker: ControlMapMarker,
    Modal,
    PickerView: ControlPicker,
    RotationLock: () => null,
    SafeAreaView: View,
    SkeletonContent: ControlSkeletonContent,
    ScrollView,
    Slider: ControlSlider,
    Video: ControlVideo,
    VideoView: ControlVideoView,
    WebView: ControlWebView,
    AnimatedImage: Animated.Image,
    AnimatedFlatList: ControlAnimatedFlatList,
    AnimatedView: Animated.View,
    Camera: ControlCamera,
    Graphic: ControlGraphic,
    MapCircle: () => null,
    MapPolyLine: () => null,
    MapPolygon: () => null,
    MapView: ControlMapView,
    SectionList,
    SvgIcon,
    SvgImage: ControlSvgImage,
    Switch,
    TextInput: ControlTextInput,
    TextInputMask: ControlInputMask,
    JitsiMeet: () => null,
    ImageViewer: () => null,
    InterstitialAd: () => null,
}

type Props = {
    appId: string;
    buildOrdersFunctions?: AllFunctions;
    windowHeight: number;
    windowWidth: number;
    children: React.ReactNode;
    buildOrders: BuildOrders;
}

const WebappContext: React.FC<Props> = ({
    appId,
    buildOrdersFunctions,
    windowHeight,
    windowWidth,
    children,
    buildOrders,
}) => {
    const [processor, setProcessor] = useState<Processor | undefined>(undefined)
    WEBAPP_DIMENTIONS.windowWidth = windowWidth
    WEBAPP_DIMENTIONS.windowHeight = windowHeight
    const language = useCurrentLanguageEffect()

    useEffect(() => {
        const currentLocalDataString = window.localStorage.getItem(`dbLocalStorage:${appId}`)
        const currentLocalData = JSON.parse(currentLocalDataString || '{}')
        store.dispatch(buildAppBootLocalStorageAction(currentLocalData))
        buildApphiveProcessor({
            firebase: {
                auth: () => odCreatorApp.auth(),
                database: () => odCreatorApp.database(),
                functions: () => firebaseGetFunctions(odCreatorApp),
                storage: () => odCreatorApp.storage(),
                messaging: () => ({
                    getToken: () => odCreatorApp.messaging().getToken(),
                    registerDeviceForRemoteMessages: async () => undefined,
                }),
            },
            alert: (title, message) => {
                // eslint-disable-next-line no-alert
                window.alert(`
                    ${title}
                    ${message}
                `)
            },
            dispatch: (action) => store.dispatch(action),
            functionDefinitions: webappFunctions,
            getApplicationState: () => store.getState().appState,
            isInDevelopment: getRenderConfig().isRenderer,
            platformOS: 'web',
            getLanguageLocale: () => navigator.language,
        }).then(setProcessor)
    }, [appId])

    useEffect(() => () => {
        if (!processor) {
            return
        }
        processor.destroy()
    }, [processor])

    const controlRendererContext = useMemo<ControlRendererContextShape | undefined>(() => {
        if (!processor) {
            return undefined
        }
        return {
            imageStaticSourcesByFileName,
            functions: {
                generateControlEvents: GenerateControlEventsFactory(webappFunctions)({
                    processor,
                    functions: buildOrdersFunctions,
                    enabledFunctions: true,
                }),
                listenerBackHandler: undefined,
                processor,
            },
            locale: language,
            dimentions: {
                window: {
                    height: windowHeight,
                    width: windowWidth,
                },
                screen: {
                    height: windowHeight,
                    width: windowWidth,
                },
                statusBarHeight: 0,
            },
            Animated, // eslint-disable-line
            Render,
            wrappers: {
                renderChildrenWrapper: undefined,
                renderControlWrapper: undefined,
            },
        }
    }, [
        language,
        processor,
        buildOrdersFunctions,
        windowHeight,
        windowWidth,
    ])

    if (!controlRendererContext) {
        return null
    }

    return (
        <ControlRendererContext.Provider
            value={controlRendererContext}
        >
            <ControlMapWrapper googleApiKey={buildOrders.googleApis?.googleApiKey}>
                {children}
            </ControlMapWrapper>
        </ControlRendererContext.Provider>
    )
}

export default WebappContext
