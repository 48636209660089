import { User } from '@firebase/auth-types'
import firebase from 'firebase/app'
import { odCreatorApp } from 'firebaseClient'
import { rendererRequestLogAuth } from '@apphiveio/controlsmanager/firebase/serverFunctions'
import base64 from 'base-64'
import { setRendererCurrentUser } from 'store/actions/application'
import getValueByPathFromUnknown from '@apphiveio/controlsmanager/shared/getValueByPathFromUnknown'
import getI18nLocale from 'shared/getI18Locale'
import authauthLoginWithProvider from 'shared/authLoginWithProvider'
import { authUserCreate, setAuthUserLocale } from './graphql'

type AppTokens = { [key: string]: string }

const getAppToken = async (appId: string) => {
    const appTokensString = await localStorage.getItem('sessiontokens:apps')
    if (!appTokensString) return undefined

    const appTokens: AppTokens = JSON.parse(appTokensString)

    return appTokens[appId]
}

type SaveAuthTokenArgs = {
    appId: string;
}

export const saveClientAppTokenInPreviewer = async (args: SaveAuthTokenArgs) => {
    const { customToken } = await rendererRequestLogAuth(odCreatorApp.functions())()

    if (!customToken) {
        return
    }

    const appTokensString = await localStorage.getItem('sessiontokens:apps')
    const appTokens: AppTokens = JSON.parse(appTokensString || '{}')

    const newAppTokens = {
        ...appTokens,
        [args.appId]: base64.encode(customToken),
    }

    await localStorage.setItem('sessiontokens:apps', JSON.stringify(newAppTokens))
}

export const removeAppToken = async (appId: string) => {
    // Remove one app sessiontoken
    const appTokensString = await localStorage.getItem('sessiontokens:apps')
    const appTokens: AppTokens = JSON.parse(appTokensString || '{}')

    delete appTokens[appId]
    await localStorage.setItem('sessiontokens:apps', JSON.stringify(appTokens))
}

const finishRenderererLogin = async (): Promise<User | null> => {
    const { currentUser } = odCreatorApp.auth()
    if (currentUser) {
        const tokenResponse = await rendererRequestLogAuth(odCreatorApp.functions())()

        if (tokenResponse.customToken) {
            await localStorage.setItem('sessiontokens:main', base64.encode(tokenResponse.customToken))
        }

        await setAuthUserLocale()({
            locale: getI18nLocale(),
        })
    }

    setRendererCurrentUser(currentUser)

    return odCreatorApp.auth().currentUser
}

export const loadMainAuthSession = async (): Promise<User | null> => {
    if (odCreatorApp.auth().currentUser) {
        await odCreatorApp.auth().signOut()
    }

    try {
        const base64Token = await localStorage.getItem('sessiontokens:main')

        if (base64Token) {
            await odCreatorApp.auth().signInWithCustomToken(base64.decode(base64Token))
        }
    } catch {
        if (odCreatorApp.auth().currentUser) {
            await odCreatorApp.auth().signOut()
        }
    }

    return finishRenderererLogin()
}

export const loadApplicationSession = async (appId: string) => {
    try {
        const sessionToken = await getAppToken(appId)

        if (!sessionToken) {
            await odCreatorApp.auth().signOut()
        } else {
            await odCreatorApp.auth().signInWithCustomToken(base64.decode(sessionToken))
        }
    } catch (error) {
        if (odCreatorApp.auth().currentUser) {
            await odCreatorApp.auth().signOut()
        }
    }
}

export const rendererEmailLogin = async (
    email: string,
    password: string,
): Promise<User | null> => {
    await odCreatorApp.auth().signInWithEmailAndPassword(email, password)

    return finishRenderererLogin()
}

export const rendererGoogleLogin = async (): Promise<User | null> => {
    await authauthLoginWithProvider({
        firebaseApp: odCreatorApp,
        provider: new firebase.auth.GoogleAuthProvider(),
        mode: 'popup',
    })

    return finishRenderererLogin()
}

export const rendererFacebookLogin = async (): Promise<User | null> => {
    await authauthLoginWithProvider({
        firebaseApp: odCreatorApp,
        provider: new firebase.auth.FacebookAuthProvider(),
        mode: 'popup',
    })

    return finishRenderererLogin()
}

export const renderForgotPassword = async (
    email: string,
): Promise<{ status: boolean; messageError?: string}> => {
    try {
        await odCreatorApp.auth().sendPasswordResetEmail(email)
        return { status: true }
    } catch (error) {
        const message = getValueByPathFromUnknown(error, ['message'])
        return { status: false, messageError: `${message}` }
    }
}

export const rendererEmailSignUp = async ({
    name,
    email,
    password,
    phone,
}: {
    name: string;
    email: string;
    password: string;
    phone: string;
}) => {
    if (name.length < 4) {
        throw new Error('The name is invalid')
    }
    if (!email) {
        throw new Error('An email is required')
    }
    if (password.length < 6) {
        throw new Error('The password is invalid')
    }

    const setAuthUser = authUserCreate()

    const response = await setAuthUser({
        name,
        email,
        password,
        phone,
        platform: 'webapp',
        typeLogIn: 'Email',
        locale: getI18nLocale(),
    })

    if (!response?.data?.authUserCreate) return null

    const user = await rendererEmailLogin(email, password)
    if (user) {
        await user.sendEmailVerification()
    }

    return user
}
