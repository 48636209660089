/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'f1gLKQ9k81khaNPqruait3.png': { uri: './images/f1gLKQ9k81khaNPqruait3.png' },
'fKUL35ud9HU1Sdm9TN7nka.png': { uri: './images/fKUL35ud9HU1Sdm9TN7nka.png' },
'4iqP5qo8rLxPDD8VXxW7GZ.png': { uri: './images/4iqP5qo8rLxPDD8VXxW7GZ.png' },
'safjnaSE8fYeqBCi8S3zmh.png': { uri: './images/safjnaSE8fYeqBCi8S3zmh.png' },
'eM7MuzdmU8kzi7yLtseXcf.png': { uri: './images/eM7MuzdmU8kzi7yLtseXcf.png' },
'3kqdyA1ZSf45mMBi2pRXvd.png': { uri: './images/3kqdyA1ZSf45mMBi2pRXvd.png' },
'qEG8t7gTK1CxKu7AG7UzXw.png': { uri: './images/qEG8t7gTK1CxKu7AG7UzXw.png' },
'tBjnFKkwa4MiysL2EtDhZv.png': { uri: './images/tBjnFKkwa4MiysL2EtDhZv.png' },
'vjUJTN6oWsku2teKrn5Qob.png': { uri: './images/vjUJTN6oWsku2teKrn5Qob.png' },
'gCfW8Cdfi23E5qoe5cZzFG.png': { uri: './images/gCfW8Cdfi23E5qoe5cZzFG.png' },
'fdAnK8TSmgzFNsCZEgdxMm.png': { uri: './images/fdAnK8TSmgzFNsCZEgdxMm.png' },
'vm2ZK19QXvd8cXx93LT6JH.png': { uri: './images/vm2ZK19QXvd8cXx93LT6JH.png' },
'da2h6A6KqyeAHYaNxDSg4U.png': { uri: './images/da2h6A6KqyeAHYaNxDSg4U.png' },
'3FwpBX9AzAiySVpxSLLAPz.png': { uri: './images/3FwpBX9AzAiySVpxSLLAPz.png' },
'jSoBYzFsqKHMwfJG6by8SC.png': { uri: './images/jSoBYzFsqKHMwfJG6by8SC.png' },
'iuuY2w19UkhqY3kDVoZ3YF.png': { uri: './images/iuuY2w19UkhqY3kDVoZ3YF.png' },
'2GEs7xzTk2MngyTMBC1uBZ.png': { uri: './images/2GEs7xzTk2MngyTMBC1uBZ.png' },
'rDs7Ldrpig38Lypc5Houpe.png': { uri: './images/rDs7Ldrpig38Lypc5Houpe.png' },
'k7Loybm69wnP4TBx2tULkh.png': { uri: './images/k7Loybm69wnP4TBx2tULkh.png' },
'1AeHLDUYQCaa8GvhpX36HU.png': { uri: './images/1AeHLDUYQCaa8GvhpX36HU.png' },
'bZtSo9QkdR8yZ6hP4M59aC.png': { uri: './images/bZtSo9QkdR8yZ6hP4M59aC.png' },
'iot4Kgdj8sbh5gcnSR4URJ.png': { uri: './images/iot4Kgdj8sbh5gcnSR4URJ.png' },
'd39WJViU7pXbpoYsvuUpkX.png': { uri: './images/d39WJViU7pXbpoYsvuUpkX.png' },
'4pC5jwmVRT51nqi5L9YMEm.png': { uri: './images/4pC5jwmVRT51nqi5L9YMEm.png' },
'sBvz1chSqMvD1Ldp1i7fF4.png': { uri: './images/sBvz1chSqMvD1Ldp1i7fF4.png' },
'cqszvAK4iTVcGdRJQak71z.jpg': { uri: './images/cqszvAK4iTVcGdRJQak71z.jpg' },
'dhgjucut9gvnyUVTArD9yn.jpg': { uri: './images/dhgjucut9gvnyUVTArD9yn.jpg' },
'1TgH89bGEQJb9bQutAikvP.png': { uri: './images/1TgH89bGEQJb9bQutAikvP.png' },
'fgmU4umanBm3PpgRhfJiRt.png': { uri: './images/fgmU4umanBm3PpgRhfJiRt.png' },
'gr1ZHtwYqahfUpGN4EaGgU.png': { uri: './images/gr1ZHtwYqahfUpGN4EaGgU.png' },
'q41QjAR4TY7jjkbVSktiih.png': { uri: './images/q41QjAR4TY7jjkbVSktiih.png' }
}

export default imageStaticSourcesByFileName
